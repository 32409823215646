import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <div className="mb-6">
      <div className="flex items-center justify-between px-6 py-3.5 bg-white border border-gray-100 rounded-full">
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-auto">
              <Link to="/">
                <img src="images/femtowork-logo.png" alt="Femtowork Logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
