import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const Privacy: React.FC = () => {
  return (
    <>
      <section className="pt-6 pb-20 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <Header />
          <div className="py-16 px-8 bg-white rounded-3xl">
            <h1 className="text-3xl font-bold mb-8">プライバシーポリシー</h1>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">1. 個人情報の取り扱いについて</h2>
              <p className="mb-4">
                フェムトワーク株式会社（以下、当社）は、お客様の個人情報を適切に取り扱い、保護することが社会的責務であると考え、
                以下のプライバシーポリシーを定め、これを遵守することをお約束いたします。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">2. 収集する情報</h2>
              <p className="mb-4">
                当社が収集する情報には以下のものが含まれます：
              </p>
              <ul className="list-disc ml-6 mb-4">
                <li>お名前</li>
                <li>メールアドレス</li>
                <li>電話番号</li>
                <li>住所</li>
                <li>その他、サービス提供に必要な情報</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">3. 個人情報の利用目的</h2>
              <p className="mb-4">
                収集した個人情報は、以下の目的で利用いたします：
              </p>
              <ul className="list-disc ml-6 mb-4">
                <li>サービスの提供および運営</li>
                <li>お問い合わせへの対応</li>
                <li>サービスの改善および新サービスの開発</li>
                <li>重要なお知らせの送信</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">4. 個人情報の管理</h2>
              <p className="mb-4">
                当社は、お客様の個人情報を適切に管理し、不正アクセス、紛失、破壊、改ざん、漏洩などを防ぐため、
                必要な安全対策を講じています。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">5. 個人情報の第三者提供</h2>
              <p className="mb-4">
                当社は、以下の場合を除き、お客様の個人情報を第三者に提供いたしません：
              </p>
              <ul className="list-disc ml-6 mb-4">
                <li>お客様の同意がある場合</li>
                <li>法令に基づく場合</li>
                <li>人の生命、身体または財産の保護のために必要がある場合</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">6. お問い合わせ</h2>
              <p className="mb-4">
                個人情報の取り扱いに関するお問い合わせは、以下の連絡先までご連絡ください：
              </p>
              <p className="mb-4">
                メール：contact@femto.work<br />
                受付時間：平日9:00〜18:00
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">7. プライバシーポリシーの変更</h2>
              <p className="mb-4">
                当社は、必要に応じて本プライバシーポリシーを変更することがあります。
                変更後のプライバシーポリシーは、当ウェブサイトに掲載した時点で効力を生じるものとします。
              </p>
            </section>

            <p className="text-sm text-gray-600">
              最終更新日：2024年1月15日
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
