import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Top from "./top";
import Privacy from "./privacy";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  )
};

export default App;
