import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Modal from "./modal";
import Header from './components/Header';
import Footer from './components/Footer';
import { GoLinkExternal } from "react-icons/go";

const Top: React.FC = () => {
  const SERVICE_ID = "service_ow238xb";
  const TEMPLATE_ID = "template_f1649ml";
  const PUBLIC_KEY = "m5vh8N-Q_vGu6x2Fa";

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allowContact, setAllowContact] = useState(false);

  const params = {
    name,
    mail,
    phoneNumber,
    subject,
    message,
    allowContact,
  };

  const clear = () => {
    setName("");
    setMail("");
    setPhoneNumber("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = () => {
    emailjs.send(SERVICE_ID, TEMPLATE_ID, params, PUBLIC_KEY).then(
      (res) => {
        clear();
        setShowModal(true);
      },
      (err) => {
        console.log("ERR", err);
      }
    );
  };

  return (
    <>
      <section className="pt-6 pb-20 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <Header />
          <div className="flex flex-wrap justify-center -m-4" id="mission">
            <div className="w-full md:w-auto p-4">
              <img className="mx-auto" src="images/device.png" />
            </div>
            <div className="w-full md:flex-1 p-4">
              <div className="flex flex-col justify-center items-center p-8 h-full text-center bg-white rounded-3xl">
                <div className="max-w-lg">
                  <span className="inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest">
                    OUR MISSION
                  </span>
                  <h1 className="font-heading mb-6 text-5xl text-gray-900 font-black tracking-tight">
                    <span>Build better services </span>
                    <span>faster than ever.</span>
                  </h1>
                  <p className="mb-8">
                    フェムトワークは個人や小さな組織向けのソフトウェアサービスを提供しています。またクライアントのニーズに応じた新規プロダクト開発やDX支援も行っています。
                  </p>
                  <div className="flex flex-wrap justify-center -m-2">
                    <div className="w-full md:w-auto p-2">
                      <a
                        className="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full"
                        href="#contact"
                      >
                        相談する
                      </a>
                    </div>
                    <div className="w-full md:w-auto p-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-gray-50 overflow-hidden" id="solutions">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 md:px-16 bg-white rounded-3xl">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="mx-auto md:ml-0 rounded-3xl"
                  src="images/business.png"
                />
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-md">
                  <span className="inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest">
                    SOLUTION
                  </span>
                  <h2 className="font-heading mb-4 text-4xl text-gray-900 font-black tracking-tight">
                    <span>Services</span>
                  </h2>
                  <p className="mb-16">
                    仕事や生活で利用できる安全で、速くて、使いやすいサービスのFemto Appsシリーズを提供しています
                  </p>
                  <div className="flex flex-wrap -m-2">
                    <div className="w-auto p-2">
                      <a href="https://apps.femto.work" target="_blank" rel="noopener noreferrer" >
                        <div className="flex px-3 py-2 bg-gray-100 rounded-full gap-x-2 ">
                          <div className="flex-1">
                            <p className="text-sm text-gray-900 font-bold">
                              Femto Apps
                            </p>
                          </div>
                          <div>
                            <GoLinkExternal />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && <Modal setShowModal={setShowModal} />}
      <section className="py-10 bg-gray-50 overflow-hidden" id="contact">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 bg-white overflow-hidden rounded-3xl">
            <div className="max-w-5xl mx-auto">
              <div className="mb-10 md:max-w-md mx-auto text-center">
                <span className="inline-block mb-4 text-sm text-blue-500 font-bold uppercase tracking-widest">
                  Contact Us
                </span>
                <h2 className="font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black tracking-tight">
                  Get connected
                </h2>
                <p className="text-gray-500">
                  お問い合わせ、ご相談、プロジェクトのご依頼など、お気軽にお問い合わせください。
                </p>
              </div>
              <form className="p-10 bg-gray-100 border border-gray-100 rounded-3xl">
                <div className="flex flex-wrap -m-5 mb-1">
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-1"
                    >
                      お名前
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-1"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-2"
                    >
                      メールアドレス
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-2"
                      type="text"
                      onChange={(e) => setMail(e.target.value)}
                      value={mail}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -m-5">
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-3"
                    >
                      電話番号
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-3"
                      type="text"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </div>
                  <div className="w-full md:w-1/2 p-5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-4"
                    >
                      件名
                    </label>
                    <input
                      className="appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full"
                      id="contactLightReverseInput2-4"
                      type="text"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -m-3.5">
                  <div className="w-full p-3.5">
                    <label
                      className="block mb-2 text-sm text-gray-500 font-bold"
                      htmlFor="contactLightReverseInput2-5"
                    >
                      メッセージ
                    </label>
                    <textarea
                      className="appearance-none px-6 py-5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-3xl"
                      id="contactLightReverseInput2-5"
                      rows={8}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </div>
                  <div className="w-full p-3.5">
                      <div className="flex flex-wrap md:justify-end -m-2">
                        <div className="w-full md:w-auto p-2">
                          <a
                            className="block w-full px-8 md:px-16 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full"
                            onClick={handleSubmit}
                          >
                            送信する
                          </a>
                        </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex flex-wrap -m-4">
                <div className="w-full md:w-1/3 p-4"></div>
                <div className="w-full md:w-1/3 p-4"></div>
                <div className="w-full md:w-1/3 p-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Top;
