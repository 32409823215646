import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <>
      <section className="py-10 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="py-16 px-8 mb-8 bg-white rounded-3xl">
            <div className="max-w-7xl mx-auto">
              <div className="flex flex-wrap items-center justify-between -m-4">
                <div className="w-auto p-4">
                  <Link className="inline-block" to="/">
                    <img src="images/femtowork-logo.png" alt="Femtowork Logo" />
                  </Link>
                </div>
                <div className="w-auto p-4">
                  <ul className="-m-6">
                    <li className="inline-flex p-6">
                      <a
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        href="#mission"
                      >
                        Mission
                      </a>
                    </li>
                    <li className="inline-flex p-6">
                      <a
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        href="#solutions"
                      >
                        Solutions
                      </a>
                    </li>
                    <li className="inline-flex p-6">
                      <a
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        href="#contact"
                      >
                        Contact us
                      </a>
                    </li>
                    <li className="inline-flex p-6">
                      <Link
                        className="inline-block text-gray-500 hover:text-gray-600 font-bold"
                        to="/privacy"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center font-bold">
            <span>Ⓒ Copyright. All rights reserved by </span>
            <a className="text-blue-500 hover:text-blue-600" href="#">
              femtowork
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export default Footer;
