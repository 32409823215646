import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";

const rootElement = document.getElementById("root");
console.log(rootElement)
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
